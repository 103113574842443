import { render, staticRenderFns } from "./Grade1TtoAL.vue?vue&type=template&id=fb9bee38&scoped=true&"
import script from "./Grade1TtoAL.vue?vue&type=script&lang=js&"
export * from "./Grade1TtoAL.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb9bee38",
  null
  
)

export default component.exports